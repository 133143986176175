module.exports = [{
      plugin: require('/home/jenkins/workspace/Release_android-china-site_main/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/jenkins/workspace/Release_android-china-site_main/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"26","matomoUrl":"https://wa.workday.com","siteUrl":"https://android.workday.com"},
    },{
      plugin: require('/home/jenkins/workspace/Release_android-china-site_main/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
